@font-face {
  font-family: "Montserrat";
  src: url(./assets/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
}

:root {
  --color--primary: #000000;
  --color--black: #000000;
  --color--white: #e2e2e2;
  --color--gray: #919191;
  --color--danger: #f44336;

  --header-height: 96px;
  --footer-height: 96px;
}

html,
body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", arial, sans-serif;
}

main {
  background-color: var(--color--primary);
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page > main {
  flex: 1;
}

.full-page-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
