.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--header-height);
  padding: 16px 24px;
  background-color: var(--color--primary);
}

.header > a,
.header__logo {
  height: 100%;
}

.header > a {
  outline: none;
  -o-transition: transform 0.25s;
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  transition: transform 0.25s;
}

.header > a:hover,
.header > a:focus-visible {
  transform: scale(1.15);
}
