.footer {
  display: flex;
  min-height: 96px;
  padding: 16px 24px;
  background-color: var(--color--primary);
}

.footer__row {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.footer__column {
  width: 50%;
}

.footer__copyright {
  font-size: 16px;
  line-height: 24px;
  color: var(--color--gray);
}

.footer__icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.footer__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  outline: none;
  -o-transition: background-color 0.25s;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.footer__icon > svg {
  fill: var(--color--white);
  -o-transition: fill 0.25s;
  -webkit-transition: fill 0.25s;
  -moz-transition: fill 0.25s;
  transition: fill 0.25s;
}

.footer__icon:hover,
.footer__icon:focus-visible {
  background-color: var(--color--white);
}

.footer__icon:hover > svg,
.footer__icon:focus-visible > svg {
  fill: var(--color--primary);
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 16px;
  }

  .footer__row {
    flex-wrap: wrap;
  }

  .footer__column {
    width: 100%;
  }

  .footer__copyright {
    text-align: center;
  }

  .footer__icons {
    justify-content: center;
  }
}
